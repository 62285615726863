import Constants from '../api/apiConstants';
import { getCookie, setCookie } from '../helpers/cookie.helper';
import {
  getBannerVisibility,
  getPayRunAlerts,
  initPayRunPostingRequest,
  getPayRunPostingRequest,
} from '../helpers/payroll.helper';
import { CROSS_APP_MESSAGE_TYPE } from '../appLoader';

function payrollWrapperEvents(credentialService) {
  document.domain = document.domain.indexOf('risepeople.com') > -1 ? 'risepeople.com' : document.domain;
  let isNavSynced = false;
  const PAYROLL_APP_LOAD_EVENT_TYPE = {
    BOOTSTRAP: false,
    HASHREDIRECT: false,
    POSTMESSAGE: false,
  };

  const PAYROLL_IFRAMES = {
    wrapperElementId: Constants.ELEMENT_IDS.PAYROLL_WRAPPER_CONTAINER,
    get wrapperElementRef() {
      return document.getElementById(this.wrapperElementId);
    },
  };

  const loadPayrollUrl = (redirectUrlMatcher = null) => {
    let url = redirectUrlMatcher;
    if (url === null) {
      url = window.location.href;
    }
    const hasMatch = url.match(`#/${Constants.CONTAINERIZED_APP_HASHURL.PAYROLL}(?!/${Constants.CONTAINERIZED_APP_HASHURL.JOURNAL_ENTRY}/)(.*)`);
    let newPayrollRoute;
    if (hasMatch) {
      [, newPayrollRoute] = hasMatch;
    } else {
      newPayrollRoute = '/Home/Index.mr';
    }
    // eslint-disable-next-line @typescript-eslint/dot-notation
    PAYROLL_IFRAMES.wrapperElementRef['src'] = Constants.getPayrollOriginalURL + newPayrollRoute;
  };

  const defaultPayrollNavSync = async (payrollNavList = null) => {
    const { Render, React, ReactDom } = await import('../render/bootstrap');
    let container = document.getElementById('subapp-container');
    if (!container) {
      container = document.createElement('div');
      container.setAttribute('id', 'subapp-container');
      document.body.appendChild(container);
    }

    if (localStorage.getItem('token') === null) {
      container.style.display = 'none';
    }

    const app = React.createElement(Render, { fromPayrollWrapper: true, payrollNavList });

    ReactDom.render(app, container);
  };

  const checkFecPayrollCookieAndLoadUrl = async (nextPayrollRoute = null) => {
    await defaultPayrollNavSync();
    await new Promise(res => setTimeout(res, 100)); // wait more for the wrapper to load
    if (getCookie(Constants.FEC_PAYROLL_COOKIE) != null) {
      loadPayrollUrl(nextPayrollRoute);
    } else {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      PAYROLL_IFRAMES.wrapperElementRef['src'] = Constants.getPayrollSsoLoginUrl;
    }
  };

  const syncPayrollNav = async payrollNavList => {
    if (payrollNavList && payrollNavList.length > 0) {
      localStorage.setItem('PAYROLL_NAVLIST', payrollNavList);
      await defaultPayrollNavSync(payrollNavList);
      isNavSynced = true;
    }
  };

  const payrollAppLoadListener = async () => {
    if (window.location.hash.indexOf(`#/${Constants.CONTAINERIZED_APP_HASHURL.PAYROLL}`) > -1 &&
    !(window.location.hash.indexOf(Constants.CONTAINERIZED_APP_HASHURL.JOURNAL_ENTRY) > -1)) {
      try {
        PAYROLL_APP_LOAD_EVENT_TYPE.BOOTSTRAP = true;
        await checkFecPayrollCookieAndLoadUrl();
      } catch (ex) {
        console.error(ex);
      }
    }
  };

  const payrollHashChangeListener = async hashchangeEvent => {
    if (window.location.hash.indexOf(`#/${Constants.CONTAINERIZED_APP_HASHURL.PAYROLL}`) > -1 &&
    !(window.location.hash.indexOf(Constants.CONTAINERIZED_APP_HASHURL.JOURNAL_ENTRY) > -1)) {
      try {
        if (!PAYROLL_APP_LOAD_EVENT_TYPE.POSTMESSAGE) {
          PAYROLL_APP_LOAD_EVENT_TYPE.HASHREDIRECT = true;
          await checkFecPayrollCookieAndLoadUrl(hashchangeEvent.newURL);
        }
        PAYROLL_APP_LOAD_EVENT_TYPE.POSTMESSAGE = false;
      } catch (ex) {
        console.error(ex);
      }
    }
  };

  // Clears the session and local storages and redirects to the location origin.
  const payrollLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    window.open('/', '_self');
  };

  const handleNavSyncEvent = async (parsedJsonData) => {
    if (parsedJsonData.userLoggedOut) {
      payrollLogout();
    }
    const payrollUrl = parsedJsonData.payrollIframeUrl;
    if (!isNavSynced) {
      await syncPayrollNav(parsedJsonData.navItems);
      setCookie(Constants.FEC_PAYROLL_COOKIE, 'true', Constants.FEC_PAYROLL_COOKIE_SESSION_TIMEOUT_MINS);
    }
    if (payrollUrl && payrollUrl.toLowerCase().indexOf(Constants.PAYROLL_DEFAULT_REDIRECT_PAGE) > -1) {
      if (window.location.href && window.location.href.toLowerCase().indexOf(Constants.PAYROLL_DEFAULT_REDIRECT_PAGE) === -1) {
        loadPayrollUrl(window.location.href);
      }
    }
    if (!PAYROLL_APP_LOAD_EVENT_TYPE.BOOTSTRAP && !PAYROLL_APP_LOAD_EVENT_TYPE.HASHREDIRECT) {
      PAYROLL_APP_LOAD_EVENT_TYPE.POSTMESSAGE = true;
      const payrollPathnameRegex = new RegExp(`${Constants.PAYROLL_PATHNAME}/(.*)`, 'i');
      const newPayrollRoute = payrollUrl.match(payrollPathnameRegex)[1];
      // eslint-disable-next-line no-restricted-globals
      location.hash = `/${Constants.CONTAINERIZED_APP_HASHURL.PAYROLL}/${newPayrollRoute}`;
    }
    PAYROLL_APP_LOAD_EVENT_TYPE.BOOTSTRAP = false;
    PAYROLL_APP_LOAD_EVENT_TYPE.HASHREDIRECT = false;
  };

  const sendResponseToPayrollEvent = (event, response) => {
    event.source.postMessage(JSON.stringify(response), '*');
  };

  const handleCheckBannerVisibility = async (event) => {
    const visible = await getBannerVisibility();

    if (visible) {
      sendResponseToPayrollEvent(event, { type: 'showCalendarFeedBanner' });
    }
  };

  const handleGetPayRunAlerts = async (event) => {
    let alertsData;
    if (credentialService.roles().some((role) => role.match('provider_*'))) {
      alertsData = {};
    } else {
      alertsData = await getPayRunAlerts();
    }
    sendResponseToPayrollEvent(event, { type: 'payRunAlertsData', alertsData });
  };

  const handleInitiatePayRunPostingRequest = async (event, payRunId) => {
    const postingRequestData = await initPayRunPostingRequest(payRunId);
    sendResponseToPayrollEvent(event, { type: 'postingRequestInitiationResponse', postingRequestData });
  };

  const handleGetPayRunPostingRequestData = async (event, payRunId) => {
    const postingRequestData = await getPayRunPostingRequest(payRunId);
    sendResponseToPayrollEvent(event, { type: 'postingRequestData', postingRequestData });
  };

  const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
  const eventHandler = window[eventMethod];
  const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';

  eventHandler(
    messageEvent,
    async evt => {
      try {
        if (evt && evt.origin && Constants.getPayrollOriginalURL.indexOf(evt.origin) > -1) {
          const eventData = typeof evt.data === 'string' ? JSON.parse(evt.data) : evt.data;
          switch (eventData.type) {
            case 'navSync': {
              handleNavSyncEvent(eventData);
              break;
            }
            case 'checkBannerVisibility': {
              handleCheckBannerVisibility(evt);
              break;
            }
            case 'loadPayRunAlertsData': {
              handleGetPayRunAlerts(evt);
              break;
            }
            case 'manualPostPayRun': {
              handleInitiatePayRunPostingRequest(evt, eventData.payRunId);
              break;
            }
            case 'getPayRunPostingRequestData': {
              handleGetPayRunPostingRequestData(evt, eventData.payRunId);
              break;
            }
            case CROSS_APP_MESSAGE_TYPE: {
              // this message will be handled by appLoader
              break;
            }

            default:
              console.error('Unexpected case');
              break;
          }
        }
      } catch (ex) {
        console.error(ex);
      }
    },
    false,
  );
  window.addEventListener('load', payrollAppLoadListener, false);
  window.addEventListener('hashchange', payrollHashChangeListener, false);
}

export { payrollWrapperEvents as default };
