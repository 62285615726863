interface ProfileImageAction {
  type: string;
  url: string;
}

const profileImage = (state: string = '', action: ProfileImageAction) => {
  switch (action.type) {
    case 'UPDATE_IMAGE':
      // eslint-disable-next-line no-param-reassign
      state = action.url;
      return state;
    default:
      return state;
  }
};

export { profileImage as default };
