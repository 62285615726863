/* eslint-disable */
import { FeatureToggleClient } from '@risepeopleinc/feature_toggle_client_typescript';
import Constants from '../api/apiConstants';

let deferredClient: Promise<FeatureToggleClient> | null = null;

const instance = new FeatureToggleClient({
  apiKey: process.env.SPLIT_API_KEY,
  features: {
    [Constants.FEATURE_FLAGS.ATS_1398_offers]: 'on',
    [Constants.FEATURE_FLAGS.GB_2519_REMOVE_EDIT_ACCESS_FOR_PLAN_ADMINS]: 'off',
    [Constants.FEATURE_FLAGS.UIU_1013_LAUNCHPAD_NEW_LOGIN_APP]: 'on',
    [Constants.FEATURE_FLAGS.PE_CENTRALIZED_REPORTING]: 'on',
    [Constants.FEATURE_FLAGS.STA_2170_NEW_TIME_APPROVAL]: 'on',
    [Constants.FEATURE_FLAGS.PENG_121_SHARED_REPORT]: 'on',
  },
});

export const getToggleClient = async ():Promise<FeatureToggleClient> => {
  if (deferredClient === null) {
    deferredClient = new Promise(async (resolve) => {
      await instance.initialize();

      resolve(instance);
    });
  }

  return deferredClient;
}
