/* eslint-disable no-console */
import {
  initGlobalState,
  MicroAppStateActions,
  registerMicroApps,
  setDefaultMountApp,
  start,
} from 'qiankun';
import { applyPolyfills, defineCustomElements } from '@risepeopleinc/rcl/loader';

import Constants from './api/apiConstants';
import payrollWrapperEvents from './payroll/PayrollWrapper';
import '../index.scss';
import globalStore from './redux/store';
import { startDynamicLoaderListener } from './appLoader';
import APPLICATION_CONFIG from './configs/applicationConfig';
import { checkLoginOrRedirect } from './helpers/auth.helper';

applyPolyfills().then(() => defineCustomElements());

const path = window.location.pathname;
const postfix = path[path.length - 1] === '/' ? '' : '/';
const APP_BASE_URL = window.location.origin + path + postfix;

globalStore.subscribe(() => {
  // TODO
});

const actions: MicroAppStateActions = initGlobalState(globalStore);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
actions.onGlobalStateChange((state, prev) => {
  // TODO
}, true);

registerMicroApps(APPLICATION_CONFIG(APP_BASE_URL, process.env.ENV_NAME === 'local'),
{
  beforeMount: async (app) => {
    if (app.name === Constants.CONTAINERIZED_APP_NAMES.PLATFORM_TOPBAR_FE) {
      return null;
    }
    const keyName = Object.entries(Constants.CONTAINERIZED_APP_NAMES).find(([, value]) => value === app.name)?.[0].toLowerCase() || app.name;
    const { datadogRum } = await import('utility_fe/datadogRumService');
    datadogRum.startView({ name: keyName, service: keyName });
    return null;
  },
  afterUnmount: () =>
    new Promise(resolve => {
      if (document.querySelectorAll('#ui-datepicker-div').length > 0) {
        document.querySelectorAll('#ui-datepicker-div').forEach(el => el.remove());
      }
      if (document.querySelectorAll('#ui-datepicker-div').length === 0) {
        resolve(true);
      }
    }),
});

setDefaultMountApp(`/app/#/${Constants.CONTAINERIZED_APP_HASHURL.PEOPLE_DIRECTORY_FE}/`);

(async () => {
  await checkLoginOrRedirect();
  const { Render, React, ReactDom, credentialService } = await import('./render/bootstrap');
  let container = document.getElementById('subapp-container');
  if (!container) {
    container = document.createElement('div');
    container.setAttribute('id', 'subapp-container');
    document.body.appendChild(container);
  }

  if (localStorage.getItem('token') === null) {
    container.style.display = 'none';
  }

  const app = React.createElement(Render, { fromPayrollWrapper: false });
  ReactDom.render(app, container);
  start({
    singular: false,
    prefetch: [Constants.CONTAINERIZED_APP_NAMES.PAYROLL],
    excludeAssetFilter: assetUrl => assetUrl && assetUrl.toLowerCase().indexOf('maps.googleapis.com') > -1,
  });

  payrollWrapperEvents(credentialService);

  startDynamicLoaderListener();
})();
