const Constants = {
  CONTAINERIZED_APP_NAMES: {
    HR: 'crab-[name]',
    PERFORMANCE: 'performance-management-fe-[name]',
    PERFORMANCE_REACT: 'performance-management-fe-react-[name]',
    SCHEDULING: 'sta-[name]',
    SCHEDULING_FE: 'scheduling_fe',
    RECRUITING: 'ats-[name]',
    ONBOARDING: '__employee_onboarding_fe_main__',
    BENEFITS: '__benefits_frontend_main__',
    TIME_OFF: 'portside-[name]',
    MARKETPLACE: 'marketplace',
    PAYROLL: 'payroll_main_fe',
    JOURNAL_ENTRY: 'journal_entries_fe',
    PLATFORM_TOPBAR_FE: 'platform_topbar_fe',
    PEOPLE_DIRECTORY_FE: 'people_directory_fe',
    OFFERS: 'offers',
    AUTHENTICATION_FE: 'authentication_fe',
    SETTINGS_FE: 'settings_fe',
    WEALTH_FE: 'wealth_fe',
    IMPORTS_FE: 'imports_fe',
    IMPORTS_FE_IMPORTER: 'imports_fe_importer',
    PAY_RUN_MANAGEMENT_FE: 'pay_run_management_fe',
    REPORTING_FE: 'reporting',
    DOCUMENT_VIEWPORT_FE: 'document_viewport_fe',
    CHATBOT_FE: 'chatbot_fe',
  },
  CONTAINERIZED_APP_HASHURL: {
    HR: 'hr',
    PERFORMANCE: 'performance',
    PERFORMANCE_REACT: 'perf-new',
    SCHEDULING: 'scheduling',
    SCHEDULING_FE: 'new-scheduling',
    RECRUITING: 'recruiting',
    ONBOARDING: 'onboarding',
    BENEFITS: 'benefits',
    TIME_OFF: 'time-off',
    MARKETPLACE: 'marketplace',
    PAYROLL: 'payroll',
    JOURNAL_ENTRY: 'journal-entry',
    PLATFORM_TOPBAR_FE: 'platform_topbar_fe',
    PEOPLE_DIRECTORY_FE: 'people',
    OFFERS: 'offers',
    AUTHENTICATION_FE: 'auth',
    SETTINGS_FE: 'settings',
    WEALTH_FE: 'wealth',
    IMPORTS_FE: 'imports',
    IMPORTS_FE_IMPORTER: 'imports/importer',
    PAY_RUN_MANAGEMENT_FE: 'pay_run_management',
    REPORTING_FE: 'reporting',
    DOCUMENT_VIEWPORT_FE: 'documents/index',
    CHATBOT_FE: 'chatbot/index',
  },
  ELEMENT_IDS: {
    PAYROLL_WRAPPER_CONTAINER: 'fec-payroll-wrapper-container',
    FEC_PAYROLL_SUBAPP_VIEWPORT_HASHID: '#subapp-payroll-viewport',
    FEC_SUBAPP_VIEWPORT_HASHID: '#subapp-viewport',
    FEC_TOPBAR_CONTAINER_ID: '#subapp-topbar',
    FEC_MARKETPLACE_VIEWPORT_HASHID: '#subapp-marketplace-viewport',
    FEC_FULLSCREEN_VIEWPORT_HASHID: '#subapp-fullscreen-viewport',
    FEC_DOCUMENT_VIEWPORT_HASHID: '#subapp-document-viewport',
  },
  FEATURE_FLAGS: {
    ATS_1398_offers: 'ATS-1398',
    GB_2519_REMOVE_EDIT_ACCESS_FOR_PLAN_ADMINS: 'GB-2519_remove_edit_access_for_plan_admins',
    UIU_1013_LAUNCHPAD_NEW_LOGIN_APP: 'UIU-1013-launchpad-new-login-app',
    PE_CENTRALIZED_REPORTING: 'PE-1184-centralized-reporting',
    PE_1291_STT_LOGIN_REDIRECT: 'PE-1291-stt-login-redirect',
    PD_1751_NEW_EMPLOYEES_TABLE: 'PD-1751-new-employees-table',
    STA_2170_NEW_TIME_APPROVAL: 'STA-2170-New-time-approval',
    PAYX_118_JOURNAL_ENTRIES: 'PAYX-118-journal-entries',
    PENG_121_SHARED_REPORT: 'PENG-121-report-sharing',
  },
  env: {
    production: {
      API_URL: 'https://gateway.risepeople.com',
      PAYROLL_BASE_URL: `${window.location.origin + window.location.pathname}#/payroll/Home/Index.mr`,
      PAYROLL_ORIGINAL_URL: process.env.PAYROLL_FRONTEND_URL,
      PAYROLL_SSO_LOGIN_URL: process.env.PAYROLL_SSO_LOGIN_URL,
    },
    local: {
      API_URL: process.env.LOCAL_BASE_API_URL,
      PAYROLL_BASE_URL: `${window.location.origin + window.location.pathname}#/payroll/Home/Index.mr`,
      PAYROLL_ORIGINAL_URL: process.env.PAYROLL_FRONTEND_URL,
      PAYROLL_SSO_LOGIN_URL: process.env.PAYROLL_SSO_LOGIN_URL,
    },
    ondemand: {
      API_URL: 'https://OD_ENV_ID.ondemand.gateway.risexyz.com',
      PAYROLL_BASE_URL: 'https://OD_ENV_ID.ondemand.risexyz.com/app/#/payroll/Home/Index.mr',
      PAYROLL_ORIGINAL_URL: process.env.PAYROLL_FRONTEND_URL,
      PAYROLL_SSO_LOGIN_URL: process.env.PAYROLL_SSO_LOGIN_URL,
    },
    test: {
      API_URL: 'https://gateway-test.risepeople.com',
      PAYROLL_BASE_URL: 'https://pftest.risepeople.com/app/#/payroll/Home/Index.mr',
      PAYROLL_ORIGINAL_URL: process.env.PAYROLL_FRONTEND_URL,
      PAYROLL_SSO_LOGIN_URL: process.env.PAYROLL_SSO_LOGIN_URL,
    },
    staging: {
      API_URL: 'https://gateway.rise-staging.com',
      PAYROLL_BASE_URL: `${window.location.origin + window.location.pathname}#/payroll/Home/Index.mr`,
      PAYROLL_ORIGINAL_URL: process.env.PAYROLL_FRONTEND_URL,
      PAYROLL_SSO_LOGIN_URL: process.env.PAYROLL_SSO_LOGIN_URL,
    },
  },
  GET_FEATURES: '/features',
  REFRESH_TOKEN_PATH: '/renew_with_refresh_token',
  ACTIVE_ORGANIZATIONS: '/multi_org_users/$USER_ID/active_organizations',
  UPDATE_TOKEN: '/multi_org_users/$USER_ID/update_token',
  CALENDAR_FEED_BANNER_VISIBILITY: '/pay_run_management/banners_visibility',
  PAY_RUN_ALERTS: '/pay_run_management/pay_run_alerts',
  PAY_RUN_POSTING_REQUESTS: '/pay_run_management/pay_run_posting_requests',
  TOKEN_EXPIRY_THRESHOLD: 60000, // 1 Minute
  get getRefreshToken() {
    return `${this.env[process.env.ENV_NAME].API_URL}${this.REFRESH_TOKEN_PATH}`;
  },
  get getFeatures() {
    return `${this.env[process.env.ENV_NAME].API_URL}${this.GET_FEATURES}`;
  },
  getActiveOrganizations(userId) {
    const ACTIVE_ORGANIZATIONS = this.ACTIVE_ORGANIZATIONS.replace('$USER_ID', userId);
    return `${this.env[process.env.ENV_NAME].API_URL}${ACTIVE_ORGANIZATIONS}`;
  },
  getEmployee(employeeId) {
    return `${this.env[process.env.ENV_NAME].API_URL}/employees/${employeeId}?include=organization`;
  },
  updateToken(userId) {
    const UPDATE_TOKEN = this.UPDATE_TOKEN.replace('$USER_ID', userId);
    return `${this.env[process.env.ENV_NAME].API_URL}${UPDATE_TOKEN}`;
  },
  getPayRunPostingRequest(payRunId) {
    return `${this.env[process.env.ENV_NAME].API_URL}${this.PAY_RUN_POSTING_REQUESTS}?pay_run_id=${payRunId}`;
  },
  initiatePayRunPostingRequest() {
    return `${this.env[process.env.ENV_NAME].API_URL}${this.PAY_RUN_POSTING_REQUESTS}`;
  },
  get getCalendarFeedBannerVisibility() {
    return `${this.env[process.env.ENV_NAME].API_URL}${this.CALENDAR_FEED_BANNER_VISIBILITY}`;
  },
  get getPayRunAlertsData() {
    return `${this.env[process.env.ENV_NAME].API_URL}${this.PAY_RUN_ALERTS}`;
  },
  get getPayrollBaseURL() {
    return `${this.env[process.env.ENV_NAME].PAYROLL_BASE_URL}`;
  },
  get getPayrollOriginalURL() {
    return `${this.env[process.env.ENV_NAME].PAYROLL_ORIGINAL_URL}`;
  },
  get getPayrollSsoLoginUrl() {
    return `${this.env[process.env.ENV_NAME].PAYROLL_SSO_LOGIN_URL}`;
  },
  getOpenTaskCount() {
    return `${this.env[process.env.ENV_NAME].API_URL}/tasks/open_tasks_count`;
  },
  PAYROLL_PATHNAME: 'everest',
  PAYROLL_DEFAULT_REDIRECT_PAGE: 'home',
  FEC_PAYROLL_COOKIE: 'FEC_PAYROLL_SESSION',
  FEC_PAYROLL_COOKIE_SESSION_TIMEOUT_MINS: 60,
  TOPBAR_FE_ENABLED: false,
  APP_PERMISSIONS_FLAGS: ['PE-806-PBAC-scheduling-time-attendance', 'PD-1064-PBAC-permissions-crab'],
};

export default Constants;
