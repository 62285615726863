import { handleResponse, handleError, postHelper } from '../api/apiUtils';
import Constants from '../api/apiConstants';

let VISIBILITY_FETCHED = false;
let BANNER_VISIBILITY = false;

const COMMON_REQUEST_DATA: RequestInit = {
  method: 'GET',
  mode: 'cors',
  cache: 'no-cache',
  credentials: 'same-origin',
  headers: {
    accept: 'application/json',
    authorization: localStorage.getItem('token'),
  },
};

const getCalendarFeedBannerVisibility = () => {
  return fetch(Constants.getCalendarFeedBannerVisibility, COMMON_REQUEST_DATA).then(handleResponse).catch(handleError);
};

const getPayRunAlertsData = () => {
  return fetch(Constants.getPayRunAlertsData, COMMON_REQUEST_DATA).then(handleResponse).catch(handleError);
};

const getPayRunPostingRequestData = (payRunId) => {
  return fetch(Constants.getPayRunPostingRequest(payRunId), COMMON_REQUEST_DATA).then(handleResponse).catch(handleError);
};

const initiatePayRunPostingRequest = (payRunId) => {
  const postData = { pay_run_id: payRunId };

  return postHelper(
    Constants.initiatePayRunPostingRequest(),
    {
      Authorization: localStorage.getItem('token'),
    },
    postData,
    20000,
  )
    .then(handleResponse)
    .catch(handleError);
};

export const getBannerVisibility = async () => {
  try {
    if (VISIBILITY_FETCHED) { return BANNER_VISIBILITY; }

    const { visible } = await getCalendarFeedBannerVisibility();
    VISIBILITY_FETCHED = true;
    BANNER_VISIBILITY = visible;

    return BANNER_VISIBILITY;
  } catch (ex) {
    return false;
  }
};

export const getPayRunAlerts = async () => {
  try {
    const data = await getPayRunAlertsData();
    return data;
  } catch (ex) {
    return {};
  }
}

export const getPayRunPostingRequest = async (payRunId) => {
  try {
    const data = await getPayRunPostingRequestData(payRunId);
    return data;
  } catch (ex) {
    return {};
  }
}

export const initPayRunPostingRequest = async (payRunId) => {
  try {
    const data = await initiatePayRunPostingRequest(payRunId);
    return data;
  } catch (ex) {
    return {};
  }
}
