import Constants from '../api/apiConstants';
import globalStore from '../redux/store';
import LOCAL_ROUTE_CONFIG from './localRouteConfig';

const loader = () => true;

const APPLICATION_CONFIG = (baseUrl, useLocal: boolean) => {
  let APP_BASE_URL = baseUrl;
  if (useLocal) {
    APP_BASE_URL = process.env.LOCAL_BASE_APP_URL;
  }
  const appRoutes = [
    {
      name: Constants.CONTAINERIZED_APP_NAMES.HR,
      entry: `${APP_BASE_URL}${Constants.CONTAINERIZED_APP_HASHURL.HR}/`,
      container: Constants.ELEMENT_IDS.FEC_SUBAPP_VIEWPORT_HASHID,
      loader,
      activeRule: [location => location.hash.split('?')[0].indexOf(`#/${Constants.CONTAINERIZED_APP_HASHURL.HR}`) > -1],
      props: { globalStore },
    },
    {
      name: Constants.CONTAINERIZED_APP_NAMES.PERFORMANCE,
      entry: `${APP_BASE_URL}${Constants.CONTAINERIZED_APP_HASHURL.PERFORMANCE}/`,
      container: Constants.ELEMENT_IDS.FEC_SUBAPP_VIEWPORT_HASHID,
      loader,
      activeRule: [location => location.hash.split('?')[0].indexOf(`#/${Constants.CONTAINERIZED_APP_HASHURL.PERFORMANCE}`) > -1],
    },
    {
      name: Constants.CONTAINERIZED_APP_NAMES.PERFORMANCE_REACT,
      entry: `${APP_BASE_URL}${Constants.CONTAINERIZED_APP_HASHURL.PERFORMANCE_REACT}/`,
      container: Constants.ELEMENT_IDS.FEC_SUBAPP_VIEWPORT_HASHID,
      loader,
      activeRule: [location => location.hash.split('?')[0].indexOf(`#/${Constants.CONTAINERIZED_APP_HASHURL.PERFORMANCE_REACT}`) > -1],
    },
    {
      name: Constants.CONTAINERIZED_APP_NAMES.SCHEDULING,
      entry: `${APP_BASE_URL}${Constants.CONTAINERIZED_APP_HASHURL.SCHEDULING}/`,
      container: Constants.ELEMENT_IDS.FEC_SUBAPP_VIEWPORT_HASHID,
      loader,
      activeRule: [
        location => location.hash.split('?')[0].indexOf(`#/${Constants.CONTAINERIZED_APP_HASHURL.SCHEDULING}`) > -1,
        location => location.pathname.indexOf('/schedules') > -1,
        location => location.pathname.indexOf('/scheduleRedirect') > -1,
        location => location.hash.indexOf('/schedules') > -1,
        location => location.hash.indexOf('/scheduleRedirect') > -1,
      ],
    },
    {
      name: Constants.CONTAINERIZED_APP_NAMES.SCHEDULING_FE,
      entry: `${APP_BASE_URL}${Constants.CONTAINERIZED_APP_HASHURL.SCHEDULING_FE}/`,
      container: Constants.ELEMENT_IDS.FEC_SUBAPP_VIEWPORT_HASHID,
      loader,
      activeRule: [
        location => location.hash.split('?')[0].indexOf(`#/${Constants.CONTAINERIZED_APP_HASHURL.SCHEDULING_FE}`) > -1,
      ],
    },

    {
      name: Constants.CONTAINERIZED_APP_NAMES.RECRUITING,
      entry: `${APP_BASE_URL}${Constants.CONTAINERIZED_APP_HASHURL.RECRUITING}/`,
      container: Constants.ELEMENT_IDS.FEC_SUBAPP_VIEWPORT_HASHID,
      loader,
      activeRule: [location => location.hash.split('?')[0].indexOf(`#/${Constants.CONTAINERIZED_APP_HASHURL.RECRUITING}`) > -1],
    },
    {
      name: Constants.CONTAINERIZED_APP_NAMES.OFFERS,
      entry: `${APP_BASE_URL}${Constants.CONTAINERIZED_APP_HASHURL.OFFERS}/`,
      container: Constants.ELEMENT_IDS.FEC_SUBAPP_VIEWPORT_HASHID,
      loader,
      activeRule: [location => location.hash.split('?')[0].indexOf(`#/${Constants.CONTAINERIZED_APP_HASHURL.OFFERS}`) > -1],
    },
    {
      name: Constants.CONTAINERIZED_APP_NAMES.ONBOARDING,
      entry: `${APP_BASE_URL}${Constants.CONTAINERIZED_APP_HASHURL.ONBOARDING}/`,
      container: Constants.ELEMENT_IDS.FEC_SUBAPP_VIEWPORT_HASHID,
      loader,
      activeRule: [location => location.hash.split('?')[0].indexOf(`#/${Constants.CONTAINERIZED_APP_HASHURL.ONBOARDING}`) > -1],
    },
    {
      name: Constants.CONTAINERIZED_APP_NAMES.BENEFITS,
      entry: `${APP_BASE_URL}${Constants.CONTAINERIZED_APP_HASHURL.BENEFITS}/`,
      container: Constants.ELEMENT_IDS.FEC_SUBAPP_VIEWPORT_HASHID,
      loader,
      activeRule: [
        location => location.hash.split('?')[0].indexOf(`#/${Constants.CONTAINERIZED_APP_HASHURL.BENEFITS}`) > -1,
        location => location.pathname.indexOf('/loading') > -1,
        location => location.hash.indexOf('#/loading') > -1,
      ],
    },
    {
      name: Constants.CONTAINERIZED_APP_NAMES.TIME_OFF,
      entry: `${APP_BASE_URL}${Constants.CONTAINERIZED_APP_HASHURL.TIME_OFF}/`,
      container: Constants.ELEMENT_IDS.FEC_SUBAPP_VIEWPORT_HASHID,
      loader,
      activeRule: [location => location.hash.split('?')[0].indexOf(`#/${Constants.CONTAINERIZED_APP_HASHURL.TIME_OFF}`) > -1],
    },
    {
      name: Constants.CONTAINERIZED_APP_NAMES.MARKETPLACE,
      entry: `${APP_BASE_URL}${Constants.CONTAINERIZED_APP_HASHURL.MARKETPLACE}/`,
      container: Constants.ELEMENT_IDS.FEC_MARKETPLACE_VIEWPORT_HASHID,
      loader,
      activeRule: [location => location.hash.split('?')[0].indexOf(`#/${Constants.CONTAINERIZED_APP_HASHURL.MARKETPLACE}`) > -1],
    },
    {
      name: Constants.CONTAINERIZED_APP_NAMES.PAYROLL,
      entry: `${APP_BASE_URL}${Constants.CONTAINERIZED_APP_HASHURL.PAYROLL}/`,
      container: Constants.ELEMENT_IDS.FEC_PAYROLL_SUBAPP_VIEWPORT_HASHID,
      loader,
      activeRule: [location => location.hash.split('?')[0].indexOf(`#/${Constants.CONTAINERIZED_APP_HASHURL.PAYROLL}`) > -1 &&
      !(location.hash.split('?')[0].indexOf(`#/${Constants.CONTAINERIZED_APP_HASHURL.PAYROLL}/${Constants.CONTAINERIZED_APP_HASHURL.JOURNAL_ENTRY}`) > -1)],
    },
    {
      name: Constants.CONTAINERIZED_APP_NAMES.JOURNAL_ENTRY,
      entry: `${APP_BASE_URL}${Constants.CONTAINERIZED_APP_HASHURL.JOURNAL_ENTRY}/`,
      container: Constants.ELEMENT_IDS.FEC_SUBAPP_VIEWPORT_HASHID,
      loader,
      activeRule: [location => location.hash.split('?')[0].indexOf(`#/${Constants.CONTAINERIZED_APP_HASHURL.PAYROLL}/${Constants.CONTAINERIZED_APP_HASHURL.JOURNAL_ENTRY}`) > -1],
    },
    {
      name: Constants.CONTAINERIZED_APP_NAMES.PLATFORM_TOPBAR_FE,
      entry: `${APP_BASE_URL}${Constants.CONTAINERIZED_APP_HASHURL.PLATFORM_TOPBAR_FE}/`,
      container: Constants.ELEMENT_IDS.FEC_TOPBAR_CONTAINER_ID,
      loader,
      activeRule: [
        location =>
          !(location.hash.split('?')[0].indexOf(`#/${Constants.CONTAINERIZED_APP_HASHURL.PAYROLL}`) > -1) &&
          !(location.hash.split('?')[0].indexOf(`#/${Constants.CONTAINERIZED_APP_HASHURL.AUTHENTICATION_FE}`) > -1),
      ],
      props: { globalStore },
    },
    {
      name: Constants.CONTAINERIZED_APP_NAMES.PEOPLE_DIRECTORY_FE,
      entry: `${APP_BASE_URL}${Constants.CONTAINERIZED_APP_HASHURL.PEOPLE_DIRECTORY_FE}/`,
      container: Constants.ELEMENT_IDS.FEC_SUBAPP_VIEWPORT_HASHID,
      loader,
      activeRule: [location => location.hash.split('?')[0].indexOf(`#/${Constants.CONTAINERIZED_APP_HASHURL.PEOPLE_DIRECTORY_FE}`) > -1],
    },
    {
      name: Constants.CONTAINERIZED_APP_NAMES.SETTINGS_FE,
      entry: `${APP_BASE_URL}${Constants.CONTAINERIZED_APP_HASHURL.SETTINGS_FE}/`,
      container: Constants.ELEMENT_IDS.FEC_SUBAPP_VIEWPORT_HASHID,
      loader,
      activeRule: [location => location.hash.split('?')[0].indexOf(`#/${Constants.CONTAINERIZED_APP_HASHURL.SETTINGS_FE}`) > -1],
    },
    {
      name: Constants.CONTAINERIZED_APP_NAMES.WEALTH_FE,
      entry: `${APP_BASE_URL}${Constants.CONTAINERIZED_APP_HASHURL.WEALTH_FE}/`,
      container: Constants.ELEMENT_IDS.FEC_SUBAPP_VIEWPORT_HASHID,
      loader,
      activeRule: [location => location.hash.split('?')[0].indexOf(`#/${Constants.CONTAINERIZED_APP_HASHURL.WEALTH_FE}`) > -1],
    },
    {
      name: Constants.CONTAINERIZED_APP_NAMES.AUTHENTICATION_FE,
      entry: `${APP_BASE_URL}${Constants.CONTAINERIZED_APP_NAMES.AUTHENTICATION_FE}/`,
      container: Constants.ELEMENT_IDS.FEC_SUBAPP_VIEWPORT_HASHID,
      loader,
      activeRule: [location => location.hash.split('?')[0].indexOf(`#/${Constants.CONTAINERIZED_APP_HASHURL.AUTHENTICATION_FE}`) > -1],
    },
    {
      name: Constants.CONTAINERIZED_APP_NAMES.PAY_RUN_MANAGEMENT_FE,
      entry: `${APP_BASE_URL}${Constants.CONTAINERIZED_APP_HASHURL.PAY_RUN_MANAGEMENT_FE}/`,
      container: Constants.ELEMENT_IDS.FEC_SUBAPP_VIEWPORT_HASHID,
      loader,
      activeRule: [location => location.hash.indexOf(`#/${Constants.CONTAINERIZED_APP_HASHURL.PAY_RUN_MANAGEMENT_FE}`) > -1],
    },
    {
      name: Constants.CONTAINERIZED_APP_NAMES.REPORTING_FE,
      entry: `${APP_BASE_URL}${Constants.CONTAINERIZED_APP_HASHURL.REPORTING_FE}/`,
      container: Constants.ELEMENT_IDS.FEC_SUBAPP_VIEWPORT_HASHID,
      loader,
      activeRule: [location => location.hash.indexOf(`#/${Constants.CONTAINERIZED_APP_HASHURL.REPORTING_FE}`) > -1],
    },
  ];

  if (useLocal) {
    const localAppRoutes = appRoutes.map(route => {
      let { entry } = route;
      if (JSON.parse(process.env.LOCAL_ROUTES).includes(route.name)) {
        entry = LOCAL_ROUTE_CONFIG[route.name];
      }
      return { ...route, entry };
    });
    return localAppRoutes;
  }
  return appRoutes;
};

export default APPLICATION_CONFIG;
