import Constants from '../api/apiConstants';
import { getToggleClient } from '../render/featureClient';

const redirectToLogin = () => {
  // prevent nested redirect URL
  const urlToRedirect = window.location.href.split('?redirectUrl')[0];

  window.location.href = `${window.location.origin}/auth/#/login?redirectUrl=${encodeURIComponent(
    `${urlToRedirect}`,
  )}`;
};

const checkLoginOrRedirect = async () => {
  const { hash } = window.location;
  if (!hash.length) return;

  const isLoginScreen = hash.startsWith('#/auth');
  if (isLoginScreen) return;

  const isSTT = hash.startsWith('#/scheduling');
  const toggleClient = await getToggleClient();
  const STTLoginRedirectFlag = await toggleClient.isEnabled(Constants.FEATURE_FLAGS.PE_1291_STT_LOGIN_REDIRECT);
  if (isSTT && !STTLoginRedirectFlag) return;

  const token = localStorage.getItem('token');
  const refreshToken = localStorage.getItem('refresh_token');
  if (!token && !refreshToken && !isLoginScreen) {
    redirectToLogin();
  }
};

// eslint-disable-next-line import/prefer-default-export
export { checkLoginOrRedirect };
