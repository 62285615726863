import Constants from '../api/apiConstants';

const LOCAL_ROUTE_CONFIG = {
  [Constants.CONTAINERIZED_APP_NAMES.HR]: 'http://localhost:8001/app/hr/',
  [Constants.CONTAINERIZED_APP_NAMES.PERFORMANCE]: 'performance-management-fe-[name]',
  [Constants.CONTAINERIZED_APP_NAMES.PERFORMANCE_REACT]: 'performance-management-fe-react-[name]',
  [Constants.CONTAINERIZED_APP_NAMES.SCHEDULING]: 'http://localhost:8009/app/scheduling/',
  [Constants.CONTAINERIZED_APP_NAMES.SCHEDULING_FE]: 'http://localhost:8011/app/new-scheduling/',
  [Constants.CONTAINERIZED_APP_NAMES.RECRUITING]: 'http://localhost:4204/app/recruiting/',
  [Constants.CONTAINERIZED_APP_NAMES.ONBOARDING]: 'http://localhost:8005/app/onboarding/',
  [Constants.CONTAINERIZED_APP_NAMES.BENEFITS]: 'http://localhost:4201/app/benefits/',
  [Constants.CONTAINERIZED_APP_NAMES.TIME_OFF]: 'http://localhost:8001/app/time-off/',
  [Constants.CONTAINERIZED_APP_NAMES.MARKETPLACE]: 'http://localhost:3000/app/marketplace/',
  [Constants.CONTAINERIZED_APP_NAMES.PAYROLL]: 'payroll_main_fe',
  [Constants.CONTAINERIZED_APP_NAMES.PLATFORM_TOPBAR_FE]: 'http://localhost:1234/app/platform_topbar_fe/',
  [Constants.CONTAINERIZED_APP_NAMES.PEOPLE_DIRECTORY_FE]: 'http://localhost:3010/app/people/',
  [Constants.CONTAINERIZED_APP_NAMES.OFFERS]: 'http://localhost:4206/app/offers/',
  [Constants.CONTAINERIZED_APP_NAMES.AUTHENTICATION_FE]: 'http://localhost:4400/app/authentication_fe/',
  [Constants.CONTAINERIZED_APP_NAMES.SETTINGS_FE]: 'http://localhost:8003/app/settings/',
  [Constants.CONTAINERIZED_APP_NAMES.WEALTH_FE]: 'http://localhost:8004/app/wealth/',
  [Constants.CONTAINERIZED_APP_NAMES.IMPORTS_FE]: 'http://localhost:4210/app/imports/',
  [Constants.CONTAINERIZED_APP_NAMES.IMPORTS_FE_IMPORTER]: 'imports_fe_importer',
  [Constants.CONTAINERIZED_APP_NAMES.PAY_RUN_MANAGEMENT_FE]: 'http://localhost:3030/app/pay_run_management_fe/',
  [Constants.CONTAINERIZED_APP_NAMES.REPORTING_FE]: 'reporting',
  [Constants.CONTAINERIZED_APP_NAMES.JOURNAL_ENTRY]: 'http://localhost:8012/app/journal-entry/',
};

export default LOCAL_ROUTE_CONFIG;
