import { LoadableApp, loadMicroApp } from 'qiankun';

import Constants from './api/apiConstants';

export const CROSS_APP_MESSAGE_TYPE = 'rise.postal';
const LoaderCommand = {
  LOAD_APP: 'rise.app.load',
  UNLOAD_APP: 'rise.app.unload',
};

const path = window.location.pathname;
const postfix = path[path.length - 1] === '/' ? '' : '/';
const APP_BASE_URL = window.location.origin + path + postfix;

const NON_ROUTE_BASED_APPS: LoadableApp<unknown>[] = [
  {
    name: Constants.CONTAINERIZED_APP_NAMES.IMPORTS_FE_IMPORTER,
    entry: `${APP_BASE_URL}${Constants.CONTAINERIZED_APP_HASHURL.IMPORTS_FE_IMPORTER}.html`,
    container: Constants.ELEMENT_IDS.FEC_FULLSCREEN_VIEWPORT_HASHID,
  },
  {
    name: Constants.CONTAINERIZED_APP_NAMES.DOCUMENT_VIEWPORT_FE,
    entry: `${APP_BASE_URL}${Constants.CONTAINERIZED_APP_HASHURL.DOCUMENT_VIEWPORT_FE}.html`,
    container: Constants.ELEMENT_IDS.FEC_DOCUMENT_VIEWPORT_HASHID,
  },
  {
    name: Constants.CONTAINERIZED_APP_NAMES.CHATBOT_FE,
    entry: `${APP_BASE_URL}${Constants.CONTAINERIZED_APP_HASHURL.CHATBOT_FE}.html`,
    container: '#',
  },
];

const SKIP_IF_ALREADY_REGISTERED = [
  Constants.CONTAINERIZED_APP_NAMES.DOCUMENT_VIEWPORT_FE,
];

const appRegistry = new Map();
let hasStarted = false;

function loadApp(name, props = {}) {
  const loadableApp = NON_ROUTE_BASED_APPS.find(app => app.name === name);
  // App not defined, should be discovered early in dev mode
  if (!loadableApp) {
    throw new Error(`Non-route based app ${name} not defined, check your configuration or command.`);
  }
  
  if (appRegistry.has(name) && SKIP_IF_ALREADY_REGISTERED.includes(name)) {
    // In theory only one instance of each app runs at a time so this is safe for all apps
    // However, only doing it for SKIP_IF_ALREADY_REGISTERED apps to be cautious
    console.warn(`App ${name} already registered, skipping`);
    return;
  }

  // eslint-disable-next-line @typescript-eslint/dot-notation
  const containerId = props['containerId'] || loadableApp['container'];

  const app = loadMicroApp(
    {
      ...loadableApp,
      container: containerId,
      props,
    },
    {
      sandbox: true,
      // `strictStyleIsolation` could be the preferred sandbox,
      // but it doesn't work with AntD Modal as the styles won't apply globally
      // sandbox: {
      //   strictStyleIsolation: true,
      // },
      singular: true,
    },
  );

  appRegistry.set(name, app);
}

function unloadApp(name) {
  const app = appRegistry.get(name);
  if (typeof app.unmount === 'function') {
    app.unmount().then(() => {
      appRegistry.delete(name);
    });
  }
}

const loaderMessageListener = (evt: MessageEvent) => {
  if (evt.origin !== window.location.origin && !Constants.getPayrollOriginalURL.includes(evt.origin)) {
    return;
  }

  const { type, envelope } = evt.data;

  if (type !== CROSS_APP_MESSAGE_TYPE || !envelope) {
    return;
  }

  const { topic, data } = envelope;

  switch (topic) {
    case LoaderCommand.LOAD_APP:
      loadApp(data.name, data.props);
      break;
    case LoaderCommand.UNLOAD_APP:
      unloadApp(data.name);
      break;
    default:
  }
};

export const startDynamicLoaderListener = () => {
  if (!hasStarted) {
    window.addEventListener('message', loaderMessageListener);
    hasStarted = true;
  }
};

export const stopDynamicLoaderListener = () => {
  if (hasStarted) {
    window.removeEventListener('message', loaderMessageListener);
    hasStarted = false;
  }
};

export type AppData<T> = {
  locales: AppLocaleData;
} & T;

export interface AppLocaleData {
  [locale: string]: {
    [key: string]: string;
  };
}
