export const setCookie = (name, value, minutes, domain = null) => {
  let cookie = `${name}=${encodeURIComponent(value)}`;
  if (minutes && typeof minutes === 'number') {
    const date = new Date();
    date.setTime(date.getTime() + minutes * 60 * 1000);
    cookie += `; expires=${date.toUTCString()}`;

    if (domain) {
      cookie += `;domain=${domain};path=/`;
    }
  }
  document.cookie = cookie;
};

export const getCookie = name => {
  const cookieArr = document.cookie.split(';');
  for (let i = 0; i < cookieArr.length; i += 1) {
    const cookiePair = cookieArr[i].split('=');
    if (name === cookiePair[0].trim()) {
      return decodeURIComponent(cookiePair[1]);
    }
  }
  return null;
};
